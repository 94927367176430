import React, { useState, useEffect } from 'react';
import { RemoteVideo, useRosterState, useRemoteVideoTileState } from 'amazon-chime-sdk-component-library-react';
import { Roles } from 'constants/role';
import VolumeMeter from 'components/Tools/VolumeMeter'
import styles from './index.module.scss';

 const LiveStreamRemoteVideo = React.memo(({ isFull }) => {
  const [attendeeId, setAttendeeId] = useState('');
  const { roster } = useRosterState();
  const { attendeeIdToTileId } = useRemoteVideoTileState();

  useEffect(() => {
    const student = Object.values(roster).find(item => item.externalUserId.split("$")[1] === Roles.ONECLASS_STUDENT);
    if (student) {
      setAttendeeId(student.chimeAttendeeId)
    }

  }, [roster])

  return attendeeId && attendeeIdToTileId[attendeeId] ?
    <>
      <RemoteVideo tileId={attendeeIdToTileId[attendeeId]} />
      {
        !isFull && <VolumeMeter className={styles.volumeMeter} width={50} height={15} attendeeId={attendeeId} />
      }
    </>
    : null
});


export default LiveStreamRemoteVideo;
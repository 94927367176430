import React, { useEffect } from 'react';
import { LocalVideo, useLocalVideo, PreviewVideo, useMeetingManager } from 'amazon-chime-sdk-component-library-react';
import VolumeMeter from 'components/Tools/VolumeMeter'
import styles from './index.module.scss';
import classnames from 'classnames';

const LiveStreamLocalVideo = ({ className, streaming = false }) => {

  const meetingManager = useMeetingManager();
  const selfAttendeeId = meetingManager.configuration.credentials.attendeeId;

  const { isVideoEnabled, toggleVideo } = useLocalVideo();

  useEffect(() => {
    if (streaming && !isVideoEnabled) {
      toggleVideo();
    }

    if (!streaming && isVideoEnabled) {
      toggleVideo();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [streaming]);

  return <div className={classnames(styles.localVideo, className)}>
    {
      streaming ? <LocalVideo /> : <PreviewVideo />
    }
    <VolumeMeter className={styles.volumeMeter} width={50} height={15} attendeeId={selfAttendeeId} />
  </div>
};


export default LiveStreamLocalVideo;
import { useRef, useEffect, useCallback, useState, useMemo } from 'react';
import { useStore, StoreTypes } from 'context';
import { InteractiveObjectEvent, ReaderToolsEvent, ReaderEvent, CanvasEvent } from 'events/EventTypes';
import { EventBus, EventBusType } from 'events/EventBus';
import { useUpdateAnnotation, useReadAnnotations, useCreateAnnotation } from 'customHooks/db'
import { useRefreshReader } from 'customHooks/reader';
import { Roles } from 'constants/role';
import { useSetPageIndex } from 'customHooks/reader';
import * as types from 'constants/actionTypes';
import useSetState from 'customHooks/setState';
import { debounce, throttle } from 'util/debounce';
import domtoimage from 'dom-to-image';
import { b64toBlob } from 'util/blob'
import { API } from 'api';
import { useAudioVideo } from 'amazon-chime-sdk-component-library-react';
import { ReaderToolType } from 'constants/ReaderTools';

let annotationSnapshot = [];
let screenShotTime;
let pingTime;
let fristCheck = false;
let studentFristOnSnapshot;
let doSnapshotEvent = false;
let isSendDraw = false;
let chatSnapshot;
let canvasListSnapshot;
let canvasCollRefSnapshot;
let courseSnapshot;
let targetId;
let finishedBeforeTimeout;

const DATA_MESSAGE_TOPIC = 'event';
const DATA_MESSAGE_LIFETIME_MS = 300000;
let enterClassTime;

export const useCourse = () => {
  const updateAnnotation = useUpdateAnnotation();
  const { readAnnotationById } = useReadAnnotations();
  const { refreshReader } = useRefreshReader();
  const { setPageIndex } = useSetPageIndex();
  const audioVideo = useAudioVideo();

  const [, globalDispatch] = useStore(StoreTypes.global);
  const [{ annotationId }] = useStore(StoreTypes.annotation);
  const [{ pageIndex, isDoublePageMode, isLiveStreamPanelShow }, readerDispatch] = useStore(StoreTypes.reader);
  const [{ canvasJSON, saveCanvasTime }] = useStore(StoreTypes.canvas);
  const [{ courseId: roomId, timeSpanId, firestore, startAt, endAt, startIn, organizationId, rewardInfo, finishedBefore }, courseDispatch] = useStore(StoreTypes.course);
  const [{ userId, name: userName, role: userRole, token }] = useStore(StoreTypes.user);
  const isCanvasSnapshotInit = useRef(false);
  const eventStatusRef = useRef({});
  const infoStatusRef = useRef({});
  const eventSubscribed = useRef(false);
  const [ onCourse, setOnCourse] = useState(false)

  const [{ data, users, syncCanvasTargetId, isSyncingCanvas, canvasQueue }, setState] = useSetState({
    data: [],
    users: [],
    syncCanvasTargetId: '',
    isSyncingCanvas: false,
    canvasQueue: [],
  });

  const broadcastEvent = useCallback(throttle(({ eventType, payload }) => {
    if (!onCourse) return;
    if (!audioVideo || !targetId || !userId) return;
    if (targetId !== userId) return;
    const action = {
      type: eventType,
      payload
    };


    audioVideo.realtimeSendDataMessage(
      DATA_MESSAGE_TOPIC,
      action,
      DATA_MESSAGE_LIFETIME_MS
    );

  },[onCourse]))

  const subscribeBroadcastedEvent = useCallback(() => {
    if (!onCourse) return;
    if (eventSubscribed.current || !audioVideo) return;
    const handler = dataMessage => {
      const data = dataMessage.json();
      console.log('dataMessage', data)
      EventBus.emit({
        event: data.type,
        payload: data.payload
      });
    }

    eventSubscribed.current = true;
    audioVideo.realtimeSubscribeToReceiveDataMessage(
      DATA_MESSAGE_TOPIC,
      handler
    );
  }, [audioVideo,onCourse])


  const getUserId = useCallback(() => {
    const id = userId.split("$")[0]
    return id
  }, [userId])


  const chatCollRef = useCallback(() => {
    if (!firestore || !roomId) return;
    return firestore.firestore().collection(`course/${roomId}/chat`)
  }, [firestore, roomId])

  const canvasListCollRef = useCallback(() => {
    if (!firestore || !roomId) return;
    return firestore.firestore().collection(`course/${roomId}/canvas`)
  }, [firestore, roomId])

  const annotaionsCollRef = useCallback(() => {
    if (!firestore || !roomId) return;
    return firestore.firestore().collection(`course/${roomId}/annotations`)
  }, [firestore, roomId])

  const canvasCollRef = useCallback(targetId => {
    if (!firestore || !roomId) return;
    const id = targetId ? targetId : getUserId();
    return firestore.firestore().collection(`course/${roomId}/users/${id}/canvas`)
  }, [firestore, roomId, getUserId])

  const courseCollRef = useCallback(targetId => {
    if (!firestore) return;
    return firestore.firestore().collection(`course`)
  }, [firestore])

  useEffect(() => {
    if (!finishedBefore || finishedBefore > 0) return
    clearTimeout(finishedBeforeTimeout)
    const time = Math.abs(finishedBefore)
    finishedBeforeTimeout = setTimeout(() => {
      courseDispatch({ type: types.SHOW_FINISHED_BUTTON, showfinishedButton: true });
    }, time);
  }, [courseDispatch, finishedBefore])

  const refreshReaderHandler = useCallback(debounce(refreshReader, 1), [refreshReader]);

  const syncAnnotationSnapshotToDB = useCallback(async (annotationSnapshot) => {
    let annotationSnapshotMap = [];
    annotationSnapshot.forEach(item => {
      annotationSnapshotMap.push(item);
    })

    const result = await readAnnotationById({ id: annotationId })

    if (result) {
      let isUpdate = false;
      const annotations = result.annotations.map(record => {
        let syncAnnotation = annotationSnapshotMap.find(v => v.pageIndex === record.pageIndex);
        if (syncAnnotation) {
          isUpdate = true;
          return {
            pageIndex: syncAnnotation.pageIndex,
            annotation: syncAnnotation.annotation
          };
        } else {
          return record;
        }
      });

      if (!isUpdate) {
        annotationSnapshotMap.map(record => {
          annotations.push(record)
        })
      }
      annotations.sort(function (a, b) {
        return a.pageIndex - b.pageIndex;
      })
      await updateAnnotation(annotationId, {
        annotations,
        isDirty: true,
        updatedAt: Date.now(),
        isDoublePageMode
      });
    }

    refreshReaderHandler();

  }, [annotationId, isDoublePageMode, readAnnotationById, refreshReaderHandler, updateAnnotation])

  const setSyncCanvasTargetId = useCallback((payload) => {
    if (!courseCollRef() || !roomId) return;
    Object.entries(payload).forEach(([key, value]) => {
      infoStatusRef.current = {
        ...infoStatusRef.current,
        ...payload
      }
    })

    courseCollRef().doc(`${roomId}`).set(payload, { merge: true })
    setState(payload)

  }, [courseCollRef, roomId, setState])

  const storgeRef = useCallback(() => {
    if (!firestore || !roomId) return;
    return firestore.storage().ref();
  }, [firestore, roomId])

  const upload = async (encodedImage) => {
    try {
      const date = new Date();
      storgeRef().child(`${roomId}/screenshot/${date.getTime()}.jpg`).put(b64toBlob(encodedImage), {
        contentType: 'image/jpeg'
      }).then((snapshot) => {
        return snapshot.ref.getDownloadURL();
      }).then(async (url) => {
        const params = { screenshotUrl: url }
        await API.coursePost(`${process.env.REACT_APP_ONECLASS_API_DOMAIN}/sessions/${roomId}/time-span/${timeSpanId}/screenshots`, params, token)
      }).catch((error) => {
        console.log("upload screen image error", error);
      });
    } catch (error) {
      console.log("upload firebase storge error", error)
    }
  };

  useEffect(() => {
    if (!startAt || !startIn) return;
    if (startIn >= 0) {
      let timeCount = startAt + startIn
      clearInterval(enterClassTime)
      enterClassTime = setInterval(() => {
        timeCount -= 1000
        if (timeCount <= startAt) {
          clearInterval(enterClassTime)
          setOnCourse(true)
          courseDispatch({ type: types.OPEN_START_COURSE_DIALOG, openStartCourseDialog: true })
        }
      }, 1000)
    }else{
      setOnCourse(true)
    }
  }, [startAt, startIn, userRole, courseDispatch])

  useEffect(() => {
    if (!firestore || !roomId) return;
    if (!fristCheck) {
      fristCheck = true

      if (organizationId) {

        // let time = new Date().getTime();
        // const isDuringClass = time >= startAt && time <= endAt;
        // if ((userRole === Roles.ONECLASS_TEACHER) && isDuringClass) {
        //   screenShotTime = setInterval(() => {
        //     domtoimage.toJpeg(document.getElementById("BookContainer"), { quality: 0.5 }).then(async (dataUrl) => {
        //       upload(dataUrl)
        //     })
        //   }, 30000);
        // }

        // if ((userRole === Roles.ONECLASS_TEACHER || userRole === Roles.ONECLASS_STUDENT) && isDuringClass) {
        //   pingTime = setInterval(async () => {
        //     time = new Date().getTime();
        //     if (time >= startAt && time <= endAt) {
        //       await API.coursePost(`${process.env.REACT_APP_ONECLASS_API_DOMAIN}/sessions/${roomId}/time-span/${timeSpanId}/ping`, {}, token)
        //     } else {
        //       window.location.reload(false);
        //     }
        //   }, 60000);
        // } else {
        //   clearInterval(screenShotTime);
        // }


      }
    }
    return () => {
      clearInterval(pingTime)
      clearInterval(screenShotTime)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firestore, roomId, timeSpanId, startAt, endAt, organizationId])

  useEffect(() => {
    if (!firestore || !roomId || !userRole || userRole === Roles.GUEST || !annotationId) return;
    // isCanvasSnapshotInit.current = true;

    if (courseSnapshot) {
      courseSnapshot();
      courseSnapshot = null
    }

    courseSnapshot = courseCollRef().doc(`${roomId}`).onSnapshot(snapshot => {
      if (snapshot.data() === undefined) return
      doSnapshotEvent = false;
      EventBus.emit({
        event: ReaderToolsEvent.ClickDragEvent
      });
      EventBus.emit({
        event: ReaderToolsEvent.SetReaderToolTypeEvent,
        payload: {
          readerToolType: ReaderToolType.Drag
        }
      });
      const { syncCanvasTargetId, isDoublePageMode, pageIndex, rewardInfo, tutorialId } = snapshot.data();
      targetId = syncCanvasTargetId
      if (targetId !== userId) {
        readerDispatch({ type: types.SET_TOOLS_IS_CONTROL, toolsIsControl: false })
        isDoublePageMode && EventBus.emit({ event: ReaderToolsEvent.TogglePageModeEvent, payload: { isDoublePageMode } });
        pageIndex !== undefined && setPageIndex({ pageIndex });
        rewardInfo && EventBus.emit({ event: ReaderToolsEvent.SetRewardInfoEvent, payload: { rewardInfo } });
      } else {
        readerDispatch({ type: types.SET_TOOLS_IS_CONTROL, toolsIsControl: true })
      }
      setState({ syncCanvasTargetId: targetId })

      if (tutorialId) {
        if (userRole !== Roles.ADMIN) {
          window.location.reload();
        }
      }

    });

    if (canvasListSnapshot) {
      canvasListSnapshot();
      canvasListSnapshot = null
    }

    canvasListSnapshot = canvasListCollRef().onSnapshot(snapshot => {
      let canvasJSONs = [];
      snapshot.docs.map(item => {
        canvasJSONs.push(JSON.parse(item.data().annotations))
      })
      targetId !== userId && canvasJSONs.length > 0 && syncAnnotationSnapshotToDB(canvasJSONs[canvasJSONs.length - 1]);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatCollRef, roomId, firestore, userRole, annotationId, userId, targetId ,pageIndex])


  const pageIndexRef = useRef(pageIndex);

  // 送畫筆紀錄到firebase
  useEffect(() => {
    if (!onCourse) return;
    if (!firestore || !roomId) return;
    if (syncCanvasTargetId === userId) {
      const syncPage = isDoublePageMode ? [2 * pageIndex, (2 * pageIndex) + 1] : [pageIndex];
      setState({ canvasQueue: canvasQueue.concat([syncPage]) });
      if (pageIndexRef.current !== pageIndex) {
        //setEventStatusToFirebase({ pageIndex })
        pageIndexRef.current = pageIndex;
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onCourse,canvasJSON, firestore, isDoublePageMode, pageIndex, roomId, syncCanvasTargetId])

  useEffect(() => {
    if (isSendDraw || !roomId) return
    if (isSyncingCanvas || canvasQueue.length === 0 || syncCanvasTargetId !== userId) return;
    setState({ isSyncingCanvas: true });
    (async () => {
      const syncPage = canvasQueue[0];
      const canvasJSONs = []
      await Promise.all(syncPage.map(page => {
        isSendDraw = true;
        canvasJSONs.push({ annotation: canvasJSON[page] || '{"objects":[]}', pageIndex: page })
      }));
      isSendDraw = false;
      setState({ isSyncingCanvas: false, canvasQueue: canvasQueue.slice(1) });

      const date = new Date();
      const localAnnotation = await readAnnotationById({ id: roomId })
      if (!localAnnotation) return;
      const annotations = localAnnotation.annotations.map(record => {
        let newData = canvasJSONs.find(v => v.pageIndex === record.pageIndex);
        if (newData) {
          return {
            pageIndex: newData.pageIndex,
            annotation: newData.annotation
          };
        } else {
          return record;
        }
      });

      await updateAnnotation(roomId, {
        annotations,
        isDirty: true,
        updatedAt: Date.now(),
        isDoublePageMode
      });

      // await annotaionsCollRef().get().then(function (snapshot) {
      //   snapshot.forEach(change => {
      //     change.ref.delete();
      //   })
      // }) 

      targetId === userId && annotaionsCollRef().doc(date.toISOString()).set({ annotations: JSON.stringify(annotations), timestamp: date.getTime() })
      targetId === userId && canvasListCollRef().doc(date.toISOString()).set({ annotations: JSON.stringify(canvasJSONs), timestamp: date.getTime() })
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canvasQueue, canvasJSON, targetId, userId, roomId]);

  useEffect(() => {
    if (!firestore || !roomId || !userId || !userRole) return;
    const isTeacher = userRole === Roles.TUTOR || userRole === Roles.ONECLASS_TEACHER;
    isTeacher && setSyncCanvasTargetId({ syncCanvasTargetId: userId, isDoublePageMode })
  }, [firestore, roomId, userId, userRole, courseCollRef, isDoublePageMode, setSyncCanvasTargetId])

  useEffect(() => {
    if (!onCourse) return;
    targetId === userId && setSyncCanvasTargetId({ isDoublePageMode, pageIndex })
  }, [onCourse,userId, isDoublePageMode, pageIndex, setSyncCanvasTargetId])


  // useEffect(() => {
  //   syncAnnotationSnapshotToDB();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [annotationSnapshot])

  const resetCourseAnnotation = useCallback(async () => {
    if (!firestore || !annotationId) return;
    const annotations = firestore.firestore().collection(`course/${annotationId}/annotations`)
    let canvasJSONs;

    await annotations.get().then(async function (snapshot) {
      snapshot.forEach(item => {
        canvasJSONs = (JSON.parse(item.data().annotations))
      })
      if (!canvasJSONs) return;
      syncAnnotationSnapshotToDB(canvasJSONs);
    })
  }, [firestore, annotationId, syncAnnotationSnapshotToDB])

  const saveRewardInfoToFirebase = useCallback((rewardInfo) => {
    if (!courseCollRef() || !roomId) return;
    courseCollRef().doc(`${roomId}`).set({ rewardInfo }, { merge: true })
  }, [courseCollRef, roomId])

  const { fireworks, good, like, trophy } = rewardInfo || {};
  useEffect(() => {
    if (targetId !== userId) return;
    saveRewardInfoToFirebase({ fireworks, good, like, trophy })
  }, [fireworks, good, like, saveRewardInfoToFirebase, trophy, userId])

  return [{ data, users }, { subscribeBroadcastedEvent, broadcastEvent, setSyncCanvasTargetId, resetCourseAnnotation }]
}

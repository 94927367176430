import React, { useEffect, useRef, useState, useCallback } from 'react';
import { ChatBubbleContainer, useAudioVideo, ChatBubble, useMeetingManager } from 'amazon-chime-sdk-component-library-react';
import Input from 'components/common/Input';
import Icon from 'components/Icon';
import { useStore, StoreTypes } from 'context';
import { Roles } from 'constants/role';
import styles from './index.module.scss';

const chatBubbleContainerStyle = `
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 5px;
  flex-direction: column;
`;


const chatBubbleStyle = (variant) => `
  margin-bottom: 1rem;
  margin-left: ${variant === 'incoming' ? '0' : 'auto'};
  margin-right: ${variant === 'incoming' ? 'auto' : '0'};
`

const DATA_MESSAGE_TOPIC = 'chat';
const DATA_MESSAGE_LIFETIME_MS = 300000;

const ChatRoom = () => {
  const meetingManager = useMeetingManager();
  const audioVideo = useAudioVideo();

  const selfAttendeeId = meetingManager.configuration.credentials.attendeeId;

  const panelRef = useRef();
  const inputRef = useRef();
  
  const [{ userId, name: userName, role: userRole }] = useStore(StoreTypes.user);
  const [{ courseId: roomId, firestore }] = useStore(StoreTypes.course);

  const [inputValue,setInputValue] = useState('');
  const [messages,setMessages] = useState([]);

  const createDataToSendObject = ({ 
    senderAttendeeId, 
    userId, 
    userName, 
    userRole, 
    message
  }) => {
    const date = new Date();

    return {
      senderAttendeeId,
      message,
      userName,
      userId,
      timestamp: date.getTime()
    }
  }

  const saveMessageToFirestore = useCallback((dataToSend) => {
    const date = new Date();
    firestore.firestore().collection(`course/${roomId}/chat`).doc(date.toISOString()).set(dataToSend)
  },[firestore, roomId])

  const sendMessageHandler = useCallback((message) => {
    if (!message) return;

    const dataToSend = createDataToSendObject({
      senderAttendeeId: selfAttendeeId,
      message: message.trim(),
      userId,
      userName,
      userRole
    });

    audioVideo.realtimeSendDataMessage(
      DATA_MESSAGE_TOPIC,
      dataToSend,
      DATA_MESSAGE_LIFETIME_MS
    );

    saveMessageToFirestore(dataToSend);
    setMessages(prev => [...prev,dataToSend])
    setInputValue('');
  },[audioVideo, saveMessageToFirestore, selfAttendeeId, userId, userName, userRole])

  const onKeyDownHandler = evt => {
    const charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode == 13) {
      sendMessageHandler(inputValue);
    }
  }

  const inputChangeHandler = useCallback(event => {
    setInputValue(event.target.value);
  },[])


  const entryChatRoom = useCallback(async () => {
    const ref = firestore.firestore().collection(`course/${roomId}/users`).doc(`${userId}`);
    const date = new Date();
    ref.set({
      userId,
      userName,
      userRole,
      online: true,
      entryTime: date.getTime(),
      leaveTime: null
    })

    const chatMessageDoc = await firestore.firestore().collection(`course/${roomId}/chat`).get();

    let messageData = [];
    chatMessageDoc.docs.map(item => {
      messageData.push(item.data())
    })

    setMessages(messageData)

    sendMessageHandler('已經進入聊天室');
  },[firestore, roomId, sendMessageHandler, userId, userName, userRole]);

  const leaveChatRoom = useCallback(()=> {
    const date = new Date();
    firestore.firestore().collection(`course/${roomId}/users`).doc(`${userId}`).set({
      online: false,
      leaveTime: date.getTime()
    }, { merge: true })
    sendMessageHandler('已經離開聊天室');
  },[firestore, roomId, sendMessageHandler, userId])

  useEffect(() => {
    if(!audioVideo) return;
    const receiveDataMessageHandler = dataMessage => {
      const data = dataMessage.json();
      const { senderAttendeeId, message, userName, userId, userRole } = data;

      const receivedData = createDataToSendObject({
        senderAttendeeId,
        message,
        userName,
        userId,
        userRole
      });

      setMessages(prev => [...prev,receivedData])
    }

    audioVideo.realtimeSubscribeToReceiveDataMessage(
      DATA_MESSAGE_TOPIC,
      receiveDataMessageHandler
    )

  },[audioVideo])


  useEffect(() => {
    userRole !== Roles.ADMIN && entryChatRoom();
    userRole !== Roles.ADMIN && window.addEventListener("beforeunload", leaveChatRoom);

    return () => {
      window.removeEventListener("beforeunload", leaveChatRoom);
    }
  },[entryChatRoom, leaveChatRoom, userRole])

  useEffect(() => {
    if (!panelRef.current) return;
    const y = panelRef.current.scrollHeight;
    panelRef.current.scrollTo(0, y);
  }, [messages.length])

 
  return <div className={styles.chatRoom}>
    <div ref={panelRef} className={styles.messagePanel}>
      <ChatBubbleContainer
        time={new Date().toString()}
        css={chatBubbleContainerStyle}
      >
        {
          messages.map(item =>{
            const variant = item.senderAttendeeId === selfAttendeeId ? 'outgoing' : 'incoming';
            return  (
              <ChatBubble
                key={`${item.timestamp}_${item.senderAttendeeId}`}
                variant={variant}
                senderName={item.userName}
                css={chatBubbleStyle(variant)}
               >
                {item.message}
              </ChatBubble>
            )
          })
        }
      </ChatBubbleContainer>
    </div>
    <div className={styles.controls}>
        <Input
          ref={inputRef}
          className={styles.messageInput}
          value={inputValue}
          onChange={inputChangeHandler}
          onKeyDown={e => onKeyDownHandler(e)}
        />
        <div className={styles.sendButton} onClick={() => sendMessageHandler(inputValue)}>
          <Icon name="SendMessage" />
        </div>
      </div>
  </div>
}

export default ChatRoom
import {
    CanvasVideoFrameBuffer
} from 'amazon-chime-sdk-js';
//import * as bodyPix from '@tensorflow-models/body-pix';

class VideoResizeProcessor {
    constructor() {

        this.targetCanvas = document.createElement('canvas');
        this.backgroundImage = document.createElement("img");
        this.targetCanvasCtx = this.targetCanvas.getContext('2d');
        this.canvasVideoFrameBuffer = new CanvasVideoFrameBuffer(this.targetCanvas);
        this.setBackGroup();
        this.init();
        return this; // when done
    }
    async init() {
        this.net = await window.bodyPix.load({
            architecture: 'MobileNetV1',
            outputStride: 16,
            multiplier: 0.75,
            quantBytes: 2
        });
    }

    async setBackGroup() {
        this.backgroundImage.src = "img/bg02.jpg";
    }

    async compositeFrame(orgCanvas, mask) {
        // if (!backgroundDarkeningMask) return;
        this.targetCanvasCtx.globalCompositeOperation = 'source-in';
        this.targetCanvasCtx.putImageData(mask, 0, 0);
        this.targetCanvasCtx.drawImage(orgCanvas, 0, 0);
        this.targetCanvasCtx.globalCompositeOperation = 'destination-over';
        this.targetCanvasCtx.drawImage(this.backgroundImage, 0, 0, this.targetCanvas.width, this.targetCanvas.height);
    }


    async process(buffers) {
        const orgCanvas = buffers[0].asCanvasElement();
        //return buffers;
        const frameWidth = orgCanvas.width;
        const frameHeight = orgCanvas.height;
        // // error handling to skip resizing
        if (!this.net || frameWidth === 0 || frameHeight === 0) {
            return buffers;
        }
        this.targetCanvas.width = frameWidth;
        this.targetCanvas.height = frameHeight;

        const segmentation = await this.net.segmentPerson(orgCanvas);

        const foregroundColor = { r: 0, g: 0, b: 0, a: 255 };
        const backgroundColor = { r: 0, g: 0, b: 0, a: 0 };
        const backgroundDarkeningMask = window.bodyPix.toMask(
            segmentation, foregroundColor, backgroundColor);


        const opacity = 0.7;
        const maskBlurAmount = 3;
        const flipHorizontal = false;


        this.compositeFrame(orgCanvas, backgroundDarkeningMask);
        // Draw the image with the background blurred onto the canvas. The edge between
        // the person and blurred background is blurred by 3 pixels.
        // window.bodyPix.drawMask(
        //     this.targetCanvas, orgCanvas, backgroundDarkeningMask, opacity, maskBlurAmount, flipHorizontal);


        // this.targetCanvasCtx.drawImage(orgCanvas, 0, 0, 300, 200,
        //     0, 0, 300, 200);

        // replace the video frame with the resized one for subsequent processor
        buffers[0] = this.canvasVideoFrameBuffer;
        return buffers;
    }
}
export default VideoResizeProcessor
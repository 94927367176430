import React, { useState, useEffect } from 'react';
import {
  ControlBar,
  Cog,
  Share,
  ContentShareControl,
  VideoInputControl,
  AudioOutputControl,
  AudioInputControl,
  useAudioVideo,
  ControlBarButton,
  ListHandRaise,
  useRosterState,
  EmojiPicker
} from 'amazon-chime-sdk-component-library-react';
import {
  ConsoleLogger,
  LogLevel,
  DefaultVideoTransformDevice
} from 'amazon-chime-sdk-js';
import { useStore, StoreTypes } from 'context';
import { EventBus } from 'events/EventBus';
import { ToolsEvent } from 'events/EventTypes';
import VideoResizeProcessor from 'customHooks/VideoResizeProcessor'
import { Roles } from 'constants/role';
import classnames from 'classnames';
import styles from './index.module.scss';

const LiveStreamControlBar = ({ isOpen }) => {
  const [{ chimeRole, isMultiple }] = useStore(StoreTypes.course);
  const audioVideo = useAudioVideo();
  const [{ userId }] = useStore(StoreTypes.user);
  const [{ toolsIsControl }] = useStore(StoreTypes.reader);

  const isTeacher = chimeRole === Roles.ONECLASS_TEACHER

  const style = `
    display: ${isOpen ? 'block' : 'none'};
    position: absolute;
    left: 50%;
    bottom: 120%;
    transform: translateX(-50%);
    height: auto;
    svg[data-testid="popover-check"] { 
      color: #000;
    }
  `;

  const [studentId, setStudentId] = useState('');
  const { roster } = useRosterState();

  useEffect(() => {
    const student = Object.values(roster).find(item => item.externalUserId.split("$")[1] === Roles.ONECLASS_STUDENT);
    if (student) {
      setStudentId(student.externalUserId)
    }

  }, [roster])

  const controlButtonProps = {
    icon: <ListHandRaise />,
    onClick: () => {
      EventBus.emit({
        event: ToolsEvent.ToolsIsControl,
        payload: { userId: toolsIsControl ? studentId : userId }
      });
    },
    label: 'Control',
    isSelected: toolsIsControl
  };

  const openSharePage = () => {
    let bookId = window.location.pathname.replace("/", "");
    let joinMeetingUrl = window.location.href.replace(`${bookId}?`, `meeting?bookId=${bookId}&`);
    if (navigator.share) {
      navigator.share({
        title: '房間分享',
        text: '可透過以下方式將連結分享',
        url: joinMeetingUrl,
      });
    }
    else {
      window.open(joinMeetingUrl, "_blank");
    }
  }

  const removeBackgroup = async () => {


    const stages = [new VideoResizeProcessor()]; // constructs  processor
    const logger = new ConsoleLogger('MyLogger', LogLevel.INFO);
    const transformDevice = new DefaultVideoTransformDevice(
      logger,
      null, // device id string
      stages
    );

    await audioVideo.chooseVideoInputDevice(transformDevice);
  }


  return (
    <ControlBar showLabels layout="left" css={style}>
      <AudioInputControl />
      <AudioOutputControl />
      <VideoInputControl />
      {
        isTeacher && (
          <>
            <ContentShareControl />
            {
              !isMultiple && <ControlBarButton {...controlButtonProps} />
            }
          </>
        )
      }
      <ControlBarButton
        icon={<Share />}
        onClick={openSharePage}
        label='Share'
      />
      <ControlBarButton
        icon={<EmojiPicker />}
        onClick={removeBackgroup}
        label='Remove'
      />
    </ControlBar >
  );
}


const LiveStreamSettingPopOver = () => {

  const [isOpen, setOpen] = useState();

  const togglePopOverHandler = () => {
    setOpen(isOpen => !isOpen)
  }

  return (
    <div className={classnames(styles.liveStreamSettingPopOver, { [styles.isOpen]: isOpen })}>
      <LiveStreamControlBar isOpen={isOpen} />
      <Cog width="2rem" onClick={togglePopOverHandler} />
    </div>
  )
};


export default LiveStreamSettingPopOver;
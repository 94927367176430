import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import styles from './index.module.scss';
import {
  faBackward,
  faForward,
  faHeadphones,
  faPlay,
  faPause,
  faTimesCircle,
  faClosedCaptioning,
  faVolumeMute,
  faVolumeDown,
  faVolumeUp,
  faUndo,
  faExpand,
  faEnvelope,
  faTools,
  faAngleLeft,
  faAngleRight,
  faChevronLeft,
  faChevronRight,
  faSearchPlus,
  faHome,
  faLongArrowAltRight,
  faTimes,
  faPencilAlt,
  faPlayCircle,
  faEllipsisV,
  faHandPaper,
  faUser,
  faBars,
  faArrowRight,
  faArrowLeft,
  faStop,
  faCog,
  faTrashAlt,
  faList,
  faCheck,
  faCaretRight,
  faCaretLeft,
  faCaretDown,
  faFileAlt,
  faGamepad,
  faFilePowerpoint,
  faStar,
  faPaperPlane,
  faComments,
  faSearchMinus,
  faItalic,
  faBold,
  faUnderline,
  faBan,
  faMouse,
  faGlobe,
  faExpandAlt,
} from '@fortawesome/free-solid-svg-icons';
import {
  faCircle,
} from '@fortawesome/free-regular-svg-icons';

import { faYoutube } from '@fortawesome/free-brands-svg-icons';

const fontawesomeIconMap = {
  backward: faBackward,
  forward: faForward,
  headphones: faHeadphones,
  play: faPlay,
  pause: faPause,
  searchPlus: faSearchPlus,
  searchMinus: faSearchMinus,
  times: faTimes,
  timesCircle: faTimesCircle,
  closedCaptioning: faClosedCaptioning,
  volumeMute: faVolumeMute,
  volumeDown: faVolumeDown,
  volumeUp: faVolumeUp,
  undo: faUndo,
  expand: faExpand,
  envelope: faEnvelope,
  tools: faTools,
  angleLeft: faAngleLeft,
  angleRight: faAngleRight,
  chevronLeft: faChevronLeft,
  chevronRight: faChevronRight,
  home: faHome,
  longArrowAltRight: faLongArrowAltRight,
  pencilAlt: faPencilAlt,
  playCircle: faPlayCircle,
  ellipsisV: faEllipsisV,
  handPaper: faHandPaper,
  user: faUser,
  bars: faBars,
  arrowRight: faArrowRight,
  arrowLeft: faArrowLeft,
  stop: faStop,
  cog: faCog,
  trashAlt: faTrashAlt,
  list: faList,
  check: faCheck,
  circle: faCircle,
  caretRight: faCaretRight,
  caretLeft: faCaretLeft,
  caretDown: faCaretDown,
  fileAlt: faFileAlt,
  gamepad: faGamepad,
  filePowerpoint: faFilePowerpoint,
  star: faStar,
  paperPlane: faPaperPlane,
  comments: faComments,
  youtube: faYoutube,
  italic: faItalic,
  bold: faBold,
  underline: faUnderline,
  ban: faBan,
  mouse: faMouse,
  globe: faGlobe,
  expandAlt: faExpandAlt
};

const PenLineType = {
  PenStraight: 'assets/icon/pen/straight.svg',
  PenCurve: 'assets/icon/pen/curve.svg'
};

const LineType = {
  Straight: 'assets/icon/line/straight.svg',
  Arrow: 'assets/icon/line/arrow.svg',
  Dotted: 'assets/icon/line/dotted.svg',
  BlackArrow: 'assets/icon/line/arrow_b.svg',
};

const Shape = {
  RightTriangle: 'assets/icon/shape/right-triangle.svg',
  IsoscelesTriangle: 'assets/icon/shape/isosceles-triangle.svg',
  Circle: 'assets/icon/shape/circle.svg',
  Rectangle: 'assets/icon/shape/rectangle.svg',
  Star: 'assets/icon/shape/star.svg'
};

const Vocabulary = {
  AudioPlay: 'assets/icon/vocabulary/audioPlay.svg',
  SwitchLang: 'assets/icon/vocabulary/switchLang.svg'
};

const Course = {
  RaiseHandGreen: 'assets/icon/course/RaiseHand-green.svg',
  RaiseHandYellow: 'assets/icon/course/RaiseHand-yellow.svg'
};

export const Stamps = require('constants/stamps.json');

const Aligns = {
  HorizontalCenter: 'assets/icon/tools/Horizontal-center.svg',
  HorizontalLeft: 'assets/icon/tools/Horizontal-left.svg',
  HorizontalRight: 'assets/icon/tools/Horizontal-right.svg',
  VerticalBottom: 'assets/icon/tools/vertical-bottom.svg',
  VerticalCenter: 'assets/icon/tools/vertical-center.svg',
  VerticalTop: 'assets/icon/tools/vertical-top.svg'
};

const BookShelf = {
  Editor: 'assets/icon/editor.svg'
};

const Expression = {
  Fireworks: 'assets/icon/expression/Fireworks.svg',
  Good: 'assets/icon/expression/Good.svg',
  Like: 'assets/icon/expression/Like.svg',
  Trophy: 'assets/icon/expression/Trophy.svg',
};

const LiveStream = {
  OpenVideo: 'assets/icon/liveStream/openVideo.svg',
  CloseVideo: 'assets/icon/liveStream/closeVideo.svg',
  OpenAudioOutput: 'assets/icon/liveStream/openAudioOutput.svg',
  CloseAudioOutput: 'assets/icon/liveStream/closeAudioOutput.svg',
  OpenAudioInput: 'assets/icon/liveStream/openAudioInput.svg',
  CloseAudioInput: 'assets/icon/liveStream/closeAudioInput.svg',
  Mic: 'assets/icon/liveStream/mic.svg',
  AudioOutput: 'assets/icon/liveStream/audioOutput.svg',
  OpenTools: 'assets/icon/liveStream/openTools.svg',
  CloseTools: 'assets/icon/liveStream/closeTools.svg',
  SendMessage: 'assets/icon/liveStream/sendMessage.svg',
  FireworksIcon: 'assets/icon/liveStream/Star.svg',
  GoodIcon: 'assets/icon/liveStream/Good.svg',
  LikeIcon: 'assets/icon/liveStream/Like.svg',
  TrophyIcon: 'assets/icon/liveStream/Trophy.svg',
  FullVideoOver: 'assets/icon/liveStream/ls_full_over.svg',
  FullVideoOut: 'assets/icon/liveStream/ls_full_out.svg',
  OffFullVideoOver: 'assets/icon/liveStream/off_full_over.svg',
  OffFullVideoOut: 'assets/icon/liveStream/off_full_out.svg',
  StudentVideoIsFull: 'assets/icon/liveStream/studentVideoIsFull.svg',
  OffCourse: 'assets/icon/liveStream/offCourse.svg',
};

const CanvasSVGObjectIcon = {
  PageStickyNote: 'assets/icon/page-stickynote.svg',
  StickyNoteMin: 'assets/icon/compress-arrows-alt-solid.svg',
  WhiteboardMin: 'assets/icon/page-whiteboard.svg'
}

const MathAreaZoom = {
  closeMathAreaZoomButton: 'assets/icon/math-area-zoom/closeButton.svg',
  prevMathAreaZoomButton: 'assets/icon/math-area-zoom/prevButton.svg',
  nextMathAreaZoomButton: 'assets/icon/math-area-zoom/nextButton.svg'
}

export const IconMap = {
  OnePage: 'assets/icon/one-page.svg',
  DoublePage: 'assets/icon/double-page.svg',
  Painting: 'assets/icon/painting.svg',
  Highlighter: 'assets/icon/highlighter.svg',
  Shape: 'assets/icon/shape.svg',
  Line: 'assets/icon/line.svg',
  DeleteAll: 'assets/icon/delete-all.svg',
  Delete: 'assets/icon/delete.svg',
  AllScreen: 'assets/icon/all-screen.svg',
  FullScreen: 'assets/icon/full-screen.svg',
  IntervalZoomIn: 'assets/icon/interval-zoom-in.svg',
  Whiteboard: 'assets/icon/whiteboard.svg',
  NumberPicker: 'assets/icon/select-number.svg',
  Draw: 'assets/icon/draw.svg',
  MouseHand: 'assets/icon/mousehand.svg',
  Mark: 'assets/icon/mark.svg',
  Select: 'assets/icon/select.svg',
  Img: 'assets/icon/image.svg',
  Text: 'assets/icon/text.svg',
  SVG: 'assets/icon/SVG.svg',
  StickyNote: 'assets/icon/stickyNote.svg',
  Link: 'assets/icon/link.svg',
  Setup: 'assets/icon/setup.svg',
  Export: 'assets/icon/export.svg',
  Close: 'assets/icon/close.svg',
  Content: 'assets/icon/content.svg',
  ContentBack: 'assets/icon/content-back.svg',
  ArrowRight: 'assets/icon/arrow-right.svg',
  ArrowLeft: 'assets/icon/arrow-left.svg',
  PageSearcher: 'assets/icon/page-searcher.svg',
  Bookmark: 'assets/icon/bookmark.svg',
  Bookcase: 'assets/icon/bookcase.svg',
  Save: 'assets/icon/save.svg',
  Extend: 'assets/icon/extend.svg',
  WindowZoomOut: 'assets/icon/window-zoom-out.svg',
  WindowZoomIn: 'assets/icon/window-zoom-in.svg',
  PageToolBox: 'assets/icon/page-tool-box.svg',
  ToolBox: 'assets/icon/tool-box.svg',
  Expression: 'assets/icon/expression.svg',
  MathTool: 'assets/icon/mathtool.svg',
  MathToolBox: 'assets/icon/math-tool-box.svg',
  Timer: 'assets/icon/timer.svg',
  Stopwatch: 'assets/icon/stopwatch.svg',
  // stamp
  ...Stamps,
  ...Aligns,
  ...Vocabulary,
  ...Shape,
  ...LineType,
  ...PenLineType,
  ...BookShelf,
  ...CanvasSVGObjectIcon,
  ...MathAreaZoom,
  ...Course,
  ...Expression,
  ...LiveStream
};

const Image = React.memo(({ className, src }) => (
  <img
    className={classnames(styles.svg, className)}
    src={src}
    alt=""
  />
))

const Icon = React.memo(({ className, name, type = 'img' }) => (
  <>
    {type === 'img' ? (
      <Image
        className={classnames(styles.svg, className)}
        src={IconMap[name]}
      />
    ) : (
        <FontAwesomeIcon icon={fontawesomeIconMap[name]} className={className} />
      )}
  </>
));

export default Icon;

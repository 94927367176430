import React, { useState, useCallback, useEffect } from 'react';
import Input from 'components/common/Input';
import { useStore, StoreTypes } from 'context';
import { useHistory, useLocation } from "react-router-dom";
import { Roles } from 'constants/role';
import styles from './index.module.scss';
import * as types from 'constants/actionTypes';
import { useFirebase } from 'customHooks/firebase';
import { short_uuid } from 'util/uuid';

const JoinTestMeeting = (props) => {
  const history = useHistory();
  const [userId, setUserId] = useState('');
  const [role, setRole] = useState(Roles.ONECLASS_STUDENT);
  const [, userDispatch] = useStore(StoreTypes.user);


  const enterRoom = useCallback(async () => {

    let userInfo = {
      type: types.UPDATE_USER_INFO,
      name: userId,
      role: role,
      userId: userId
    }
    userDispatch(userInfo);
    history.push(`/${props.bookId}?roomId=${props.roomId}`);

  }, [userId, userDispatch]);


  return (
    <form>
      <h2>會議ID:{props.roomId}</h2>
      <label>
        使用者名稱:<br />
        <input type="text" value={userId} onChange={event => setUserId(event.target.value)} />
      </label>
      <label className={styles.roleOption}>
        角色:<br />
        <select value={role} onChange={event => setRole(event.target.value)}>
          <option value={Roles.ONECLASS_STUDENT}>學生</option>
          <option value={Roles.ONECLASS_TEACHER}>老師</option>
        </select>
      </label>
      <input type="button" value="加入房間" onClick={enterRoom} />
    </form>
  )
}

const CreateTestMeeting = () => {
  const history = useHistory();
  const [roomId, setRoomId] = useState('');
  const [userId, setUserId] = useState('');
  const [bookId, setBookId] = useState('c7533171c5c9d2bd');
  const [, userDispatch] = useStore(StoreTypes.user);

  const createRoom = useCallback(async () => {

    if (!bookId) {
      alert("範本尚未選擇");
      return;
    }

    const roomId = short_uuid();
    setRoomId(roomId);

    let userInfo = {
      type: types.UPDATE_USER_INFO,
      name: userId,
      role: Roles.ONECLASS_TEACHER,
      userId: userId
    }
    userDispatch(userInfo);
    history.push(`/${bookId}?roomId=${roomId}`);

  }, [userId, bookId, userDispatch]);


  return (
    <form>
      <label>
        使用者名稱:<br />
        <input type="text" value={userId} onChange={event => setUserId(event.target.value)} />
      </label>

      {/* <label>
        角色:<br />
        <select onChange={event => setRole(event.target.value)}>
          <option></option>
          <option value='ONECLASS_TEACHER'>老師</option>
          <option value='ONECLASS_STUDENT'>學生</option>
        </select>
      </label> */}

      <label>
        房間介面:<br />
        <select onChange={event => setBookId(event.target.value)}>
          <option>純白</option>
          {/* <option value='6373dc804106e3f2'>範本一</option> */}
        </select>
      </label>
      <input type="button" value="建立房間" onClick={createRoom} />

    </form>
  )
}

const LiveStreamTestLogin = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const roomId = query.get('roomId');
  const bookId = query.get('bookId');

  if (roomId)
    return <JoinTestMeeting roomId={roomId} bookId={bookId} />;

  return <CreateTestMeeting />;
}

export default LiveStreamTestLogin



import React, { useState, useEffect } from 'react';
import {
  RemoteVideo,
  useMeetingManager,
  useRemoteVideoTileState,
} from 'amazon-chime-sdk-component-library-react';
import { useStore, StoreTypes } from 'context';
import * as types from 'constants/actionTypes';
import VolumeMeter from 'components/Tools/VolumeMeter'
import styles from './index.module.scss';


export const LiveStreamMasterVideo = React.memo(({ isFull }) => {
  const [, courseDispatch] = useStore(StoreTypes.course);
  const meetingManager = useMeetingManager();
  const meetingId = meetingManager.meetingId;
  const [attendeeId, setAttendeeId] = useState('');

  const { attendeeIdToTileId } = useRemoteVideoTileState();

  useEffect(() => {
      if (!meetingId || attendeeId) return;
      const getTeacherAttendeeId = async () => {
          const response =
              await fetch(`${process.env.REACT_APP_CHIME_API_URL}/getTeacher?meetingId=${meetingId}`);
          const { data } = await response.json();
          if (data) {
              setAttendeeId(data.AttendeeId)
              courseDispatch({ 
                type: types.SET_CHIME_TEACHER_ATTENDEE_ID, 
                payload: { attendeeId: data.AttendeeId } 
              })
          }
      }

      getTeacherAttendeeId();
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetingId, attendeeIdToTileId])


  return attendeeId && attendeeIdToTileId[attendeeId] ?
      <>
          <RemoteVideo tileId={attendeeIdToTileId[attendeeId]} />
          {
              !isFull && <VolumeMeter className={styles.volumeMeter} width={50} height={15} attendeeId={attendeeId} />
          }
      </>
      : null
});

export default LiveStreamMasterVideo;

import React, { useState, useEffect } from 'react';
import { useStore, StoreTypes } from 'context';
import LiveStreamLocalVideo from 'components/LiveStreamLocalVideo'
import ChatRoom from 'components/ChatRoom';
import classnames from 'classnames';
import DialogModule from 'components/Dialogs/'
import StudentRaiseButton from './StudentRaiseButton'
import styles from './index.module.scss';
import { useStudentList } from 'customHooks/studentList';
import { useOrderStudent } from 'customHooks/orderStudent';
import { useRemoteVideoTileState, useRosterState } from 'amazon-chime-sdk-component-library-react';
import LiveStreamRemoteVideoGroup from 'components/LiveStreamRemoteVideoGroup';
import LiveStreamEmojiCounts from 'components/LiveStreamEmojiCounts';
import LiveStreamRemoteVideo from 'components/LiveStreamRemoteVideo';
import { Roles } from 'constants/role';
import { ReaderEvent } from 'events/EventTypes';
import { EventBus } from 'events/EventBus';
import Icon from 'components/Icon';

const StudentListModal = ({ setStudentListOpen, stageCount }) => {
  return (
    <div className={styles.listDialogBottom}>
      <div className={styles.bottomText}>目前上台人數:{stageCount}</div>
      <div
        className={styles.closeListDialog}
        onClick={() => {
          setStudentListOpen(false)
        }}
      >
        關閉
      </div>
    </div>
  )
};


const MessageDialogAction = ({ setMessageOpen }) => (
  <div className={styles.closeDialog}
    onClick={() => setMessageOpen(false)}
  >
    關閉
  </div>
)



const MessageDialogContent = () => (
  <div>
    已超過舉手人數
  </div>
);


const ListNameTextField = ({
  studentAttendees,
  stageCount,
  showMessageDialog
}) => {
  const { attendeeIdToTileId } = useRemoteVideoTileState();
  const { orderStudent, unOrderStudent } = useOrderStudent();

  return (
    <div className={styles.listContent}>
      <div className={styles.title}>
        <div className={styles.titleText}>學生名單</div>
        <div className={styles.totalUser}>
          房間人數:{studentAttendees.length}
        </div>
      </div>
      {
        // <div className={styles.controlButtons}>
        //   <div>全部靜音</div>
        //   <div>其他待開發工具</div>
        // </div>
      }
      <div className={styles.fieldName}>
        <div className={styles.studentName}>學生暱稱</div>
        <div className={styles.controls}>操作</div>
      </div>
      <div className={styles.lists}>
        {
          studentAttendees.map(attendee => {
            const { chimeAttendeeId, name } = attendee;
            const itemInfo = {
              attendeeId: chimeAttendeeId, orderStudent, unOrderStudent
            }
            return (
              <div className={styles.listItem} key={chimeAttendeeId}>
                <div className={styles.text}>
                  {name}
                </div>
                <div className={styles.raiseHand}>
                  <StudentRaiseButton
                    isActive={attendeeIdToTileId[chimeAttendeeId] !== undefined}
                    itemInfo={itemInfo}
                    onStageCount={stageCount}
                    showMessageDialog={showMessageDialog} />
                </div>
              </div>
            )
          })
        }
      </div>
    </div>
  )
}
const LiveStreamMasterPanel = () => {
  const [students, setStudents] = useState(null);
  const [{ isMultiple, isFullVideo }] = useStore(StoreTypes.course);
  const studentAttendees = useStudentList();
  const { tiles, size: stageCount } = useRemoteVideoTileState();


  const [studentListOpen, setStudentListOpen] = useState(false);
  const [messageOpen, setMessageOpen] = useState(false);
  const [videoAttendees, setVideoAttendees] = useState(new Set());
  const { roster } = useRosterState();

  useEffect(() => {
    if (isMultiple) return;
    const studentsInfo = Object.values(roster).find(item => item.externalUserId.split("$")[1] === Roles.TUTOR_USER);
    setStudents(studentsInfo)
  }, [isMultiple, students, roster])

  const showMessageDialog = () => {
    setMessageOpen(true)
  }

  const videoFullScreenHandler = (isFull) => {
    EventBus.emit({ event: ReaderEvent.sendFullVideoEvent, payload: { isFullVideo: isFull } });
  }

  return (
    <div className={classnames(styles.discussionPanel, styles.wrapper)}>
      <div id="content" className={styles.chatContent}>
        {
          !isMultiple && (
            <div className={classnames(styles.localVideo, { [styles.localVideoHide]: isFullVideo, [styles.localVideoBG]: isFullVideo })}>
              {
                !isFullVideo && <LiveStreamRemoteVideo isFull={isFullVideo}/>
              }
              {
                <div
                  className={classnames(styles.offFullVideoBtn, { [styles.offFullVideoBtnHide]: !isFullVideo })}
                  onClick={() => videoFullScreenHandler(false)}
                >
                  <Icon name="OffFullVideoOver" />
                  <Icon name="OffFullVideoOut" />
                </div>
              }
              {
                <div className={classnames(styles.fullVideoBtn, { [styles.fullVideoBtnHide]: isFullVideo })}
                  onClick={() => videoFullScreenHandler(true)}>
                  <Icon name="FullVideoOver" />
                  <Icon name="FullVideoOut" />
                </div>
              }
            </div>
          )
        }
        <div className={styles.localVideo}>
          <LiveStreamLocalVideo streaming={true} />
        </div>
        {
          isMultiple? (
            <>
              <div className={styles.remoteVideoContainer}>
                <LiveStreamRemoteVideoGroup />
              </div>
              <div className={styles.controls} onClick={() => setStudentListOpen(true)}>
                <div className={styles.studentListbutton} >
                  學生名單
              </div>
              </div>
            </>
          ) : (
              <LiveStreamEmojiCounts />
            )
        }
        <div className={styles.chatRoomWrapper}>
          <ChatRoom />
        </div>
      </div>
      <DialogModule
        style={{ zIndex: 10000 }}
        open={studentListOpen}
        setOpen={setStudentListOpen}
        content={
          <ListNameTextField
            studentAttendees={studentAttendees}
            stageCount={stageCount}
            showMessageDialog={showMessageDialog}
          />
        }
        action={
          <StudentListModal
            setStudentListOpen={setStudentListOpen}
            stageCount={stageCount}
          />
        }
      />

      <DialogModule
        style={{ zIndex: 10000 }}
        open={messageOpen}
        setOpen={setMessageOpen}
        content={
          <MessageDialogContent />
        }
        action={
          <MessageDialogAction setMessageOpen={setMessageOpen} />
        }
      />
    </div>
  )
}

export default LiveStreamMasterPanel;

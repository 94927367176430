import React, { useEffect } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import Provider from './context';
import { AppService } from 'components/AppService';
import Bookshelf from 'components/Bookshelf';
import { BookStore } from 'components/BookStore';
import Placeholder from 'components/Placeholder';
import ReaderActivity from 'components/ReaderActivity';
import LiveStreamTestLogin from 'components/LiveStreamTestLogin'
import { Beforeunload } from 'components/Beforeunload';
import { useCourse } from 'customHooks/course';
import { ThemeProvider } from 'styled-components';
import { MeetingProvider, lightTheme } from 'amazon-chime-sdk-component-library-react';
import { LogLevel } from 'amazon-chime-sdk-js';


const SubscribeBroadcastedEventService = () => {
  const [_, { subscribeBroadcastedEvent }] = useCourse();

  useEffect(() => {
    subscribeBroadcastedEvent();
  }, [subscribeBroadcastedEvent])

  return null;
}

const HookedBrowserRouter = ({ children }) => (
  <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
    <Route>
      {(routeProps) => (
        <Provider router={routeProps}>
          {children}
        </Provider>
      )}
    </Route>
  </BrowserRouter>
);

const postLogConfig = {
  name: process.env.REACT_APP_ONECLASS_PROJECT_ID,
  batchSize: 10,
  intervalMs: 5000,
  url: process.env.REACT_APP_CHIME_LOG_API_URL,
  logLevel: LogLevel.WARN
};

const simulcastEnabled = true;

const App = () => {
  return (
    <Beforeunload>
      <ThemeProvider theme={lightTheme} >
        <MeetingProvider postLogConfig={postLogConfig} simulcastEnabled={simulcastEnabled} logLevel={LogLevel.INFO}>
          <HookedBrowserRouter>
            <AppService />
            <SubscribeBroadcastedEventService />
            <Switch>
              <Route exacr path='/meeting' component={LiveStreamTestLogin} />
              <Route exact path='/bookshelf' component={Bookshelf} />
              <Route exact path='/bookstore' component={BookStore} />
              <Route exact path='/loading' component={Placeholder} />
              <Route exact path='/:bookId' component={ReaderActivity} />
              <Redirect path='*' to='/bookshelf' />
            </Switch>
          </HookedBrowserRouter>
        </MeetingProvider>
      </ThemeProvider>
    </Beforeunload>
  );
};

export default App;



import React, { useState } from 'react';
import styles from './index.module.scss';
import Icon from 'components/Icon';


const StudentRaiseButton = (props) => {
    const { itemInfo, onStageCount, isActive, showMessageDialog } = props
    const { attendeeId, orderStudent, unOrderStudent } = itemInfo
    return <div className={styles.button}>
        <div
            onClick={() => {
                if (isActive) {
                    unOrderStudent(attendeeId)
                } else {
                    if (onStageCount < 7) {
                        orderStudent(attendeeId)
                    } else {
                        showMessageDialog()
                    }
                }
            }}
            title={isActive ? "點下台" : "點上台"}
        >
            <Icon name={isActive ? "RaiseHandYellow" : "RaiseHandGreen"} />
        </div>
    </div>
}
export default StudentRaiseButton


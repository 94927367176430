import React, { useEffect } from 'react';
import { useStore, StoreTypes } from 'context';
import { EventBus } from 'events/EventBus';
import { UserEvent } from 'events/EventTypes';
import { AnnotationType } from 'constants/annotationTypes';
import { Roles } from 'constants/role';
import { usePullAnnotations, useRefreshReader } from 'customHooks/reader';
import { usePreparationSnapshot } from 'customHooks/preparation';

import { ReaderEvent, ReaderToolsEvent } from 'events/EventTypes';
import { useReadAnnotations } from 'customHooks/db';
import { usePreparation } from 'customHooks/preparation';
import { useUpdateDisplayLanguage, useUpdateUserSettings } from 'customHooks/userSetting';
import { UserSettingsAPI } from 'api/UserSettingsAPI';
import { useFirebase } from 'customHooks/firebase';
import * as types from 'constants/actionTypes';

export const useAnnotationChecker = (props) => {
    const { bookId, paramsRole, paramsActivityId, paramsTeacherId, paramsInteractiveObjectId, roomId, studentId, timeSpanId, code, otp  } = props
    const [{ annotationId, annotationType }] = useStore(StoreTypes.annotation);
    const [, readerDispatch] = useStore(StoreTypes.reader);

    const [{ books }] = useStore(StoreTypes.books);
    const [{ role, token, userId, name }, userDispatch] = useStore(StoreTypes.user);
    const [, courseDispatch] = useStore(StoreTypes.course);

    const { preparationSnapshot } = usePreparationSnapshot();
    const { getPreparationList } = usePreparation();
    const { refreshReader } = useRefreshReader();
    const { updateDisplayLanguage } = useUpdateDisplayLanguage();
    const updateUserSettings = useUpdateUserSettings();
    const { readAnnotations, readAnnotationById } = useReadAnnotations();
    const { firebaseInitialize } = useFirebase();

    let payload;

    useEffect(() => {
        console.log(annotationId, "------paramsActivityId------", paramsActivityId);
        const book = books.find(book => book.bookId === bookId);
        if (!book) return;
        if (!annotationId && !paramsActivityId && !paramsInteractiveObjectId) {
            EventBus.emit({
                event: UserEvent.GetUserIdentityEvent,
                payload: {
                    roomId,
                    studentId,
                    timeSpanId,
                    code,
                    otp,
                    callback: (async ({ role, userId, userName, courseInfo }) => {
                        let type = AnnotationType.GUEST;
                        switch (role) {
                            case Roles.GUEST:
                                type = AnnotationType.GUEST
                                break;
                            case Roles.TEACHER:
                            case Roles.PARENT:
                            case Roles.STUDENT:
                                type = AnnotationType.CLASS_PREPARATION
                                break;
                            case Roles.EDITOR:
                                type = AnnotationType.INTERACTIVE_OBJECT
                                break;
                            case Roles.TUTOR:
                                type = AnnotationType.ONE_TUTOR
                                break;
                            case Roles.TUTOR_USER:
                                type = AnnotationType.ONE_TUTOR
                                break;
                            case Roles.ONECLASS_TEACHER:
                            case Roles.ADMIN:
                            case Roles.ONECLASS_STUDENT:
                                type = AnnotationType.ONE_CLASS
                                break;
                            default:
                                break;
                        }

                        //撈出indexdb裡面的備課資料，取第一筆進書
                        payload = {
                            bookId,
                            annotationType: type,
                            name: "未命名",
                            marks: []
                        };

                        if (token) {
                            await getPreparationList(bookId, token, type);
                        }

                        let callback = null;
                        if (type !== AnnotationType.ONE_TUTOR && type !== AnnotationType.ONE_CLASS) {
                            let results = await readAnnotations({ bookId, annotationType: type });
                            if (results.length > 0) {
                                const latestAnnotation = results.filter((annotation) => !annotation.isDeleted).sort((a, b) => {
                                    return b.updatedAt - a.updatedAt;
                                })[0];
                                callback = () => {
                                    refreshReader(latestAnnotation)
                                };
                                payload.annotationId = latestAnnotation.id;
                                payload.name = latestAnnotation.name;
                                payload.marks = latestAnnotation.marks;
                                payload.annotations = latestAnnotation.annotations;
                                if (type === AnnotationType.CLASS_PREPARATION) {
                                    //preparationSnapshot(latestAnnotation.id, token)
                                }
                            }
                        } else {
                            const result = await readAnnotationById({ id: roomId });
                            if (result) {
                                payload.annotationId = result.id;
                                payload.name = result.name;
                                payload.marks = result.marks;
                                payload.annotations = result.annotations;
                            } else {
                                payload.annotationId = roomId;
                                payload.name = userName;
                                payload.createNewAnnotation = true;
                                payload.marks = [];
                                payload.annotations = [];
                            }

                            if (role === Roles.GUEST) {
                                alert("您不是該課程的成員")
                            } else {
                                let enterCourse = false;
                                let isFreeGroup = false;
                                if (courseInfo) {
                                    isFreeGroup = courseInfo.organizationId === undefined ? true : false;
                                    //courseInfo.startIn=30000
                                    let { startAt, endAt, startIn } = courseInfo
                                    const time = startAt - (startIn)
                                    if (time >= startAt && time <= endAt) {
                                        enterCourse = true;
                                    }
                                    courseDispatch({ type: types.SET_COURSE_INFO, courseInfo: courseInfo })
                                }
                                
                                switch (role) {
                                    case Roles.TUTOR:
                                        readerDispatch({ type: types.TOGGLE_LIVE_STREAM_PANEL, isLiveStreamPanelShow: true });
                                        break;
                                    case Roles.ONECLASS_TEACHER:
                                        firebaseInitialize()
                                        courseDispatch({ type: types.SET_CHIME_ROLE, chimeRole: role })
                                        if (enterCourse) {
                                            !isFreeGroup && readerDispatch({ type: types.TOGGLE_LIVE_STREAM_PANEL, isLiveStreamPanelShow: true });
                                        } else {
                                            readerDispatch({ type: types.TOGGLE_LIVE_STREAM_PANEL, isLiveStreamPanelShow: false });
                                        }
                                        break;
                                    case Roles.TUTOR_USER:
                                        readerDispatch({ type: types.TOGGLE_LIVE_STREAM_PANEL, isLiveStreamPanelShow: true });
                                        break;
                                    case Roles.ADMIN:
                                    case Roles.ONECLASS_STUDENT:
                                        firebaseInitialize()
                                        courseDispatch({ type: types.SET_CHIME_ROLE, chimeRole: role })
                                        if (enterCourse) {
                                            !isFreeGroup && readerDispatch({ type: types.TOGGLE_LIVE_STREAM_PANEL, isLiveStreamPanelShow: true });
                                        } else {
                                            readerDispatch({ type: types.TOGGLE_LIVE_STREAM_PANEL, isLiveStreamPanelShow: false });
                                        }
                                        break;
                                    default:
                                        break;
                                }
                            }
                        }

                        EventBus.emit({
                            event: ReaderEvent.CreateAndEnterAnnotationEvent,
                            payload: {
                                ...payload,
                                callback
                            }
                        });
                    })
                }
            });
        } else {
            if (annotationType === AnnotationType.CLASS_PREPARATION) {
                //preparationSnapshot(annotationId, token)
                refreshReader();
            } else if (annotationType === AnnotationType.GUEST) {
                refreshReader();
            }
        }

        return payload
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [books, token]);
}